<template>
    <v-dialog v-model="dialog" max-width="1024px">
        <v-card>
            <v-card-title
                class="text-h5 aviso"
                style="
                    justify-content: center;
                    background: #1d2735;
                    color: white;
                "
            >
                Enviar Mail Factura
            </v-card-title>
            <v-card-text style="text-align: center">
                <v-row style="margin-top: 1rem">
                    <v-col cols="12">
                        <RichTextComponent
                            v-model="descripcion"
                        ></RichTextComponent>
                    </v-col>
                    <v-col cols="12">
                        <v-file-input
                            v-model="files"
                            counter
                            multiple
                            show-size
                            small-chips
                            truncate-length="15"
                            outlined
                            label="Seleccione archivos adjuntos al email"
                        ></v-file-input>
                    </v-col>
                </v-row>
            </v-card-text>
            <v-card-actions class="pt-3">
                <v-spacer></v-spacer>
                <v-btn color="error" large @click="dialog = false"
                    >Cancelar</v-btn
                >
                <v-btn color="success" large @click="SendMail()"
                    >Confirmar</v-btn
                >
                <v-spacer></v-spacer>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>
<script>
import RichTextComponent from "../../tickets/componentes/RichTextComponent.vue";
export default {
    components: {
        RichTextComponent,
    },
    props: ["value", "id_factura", "kit"],
    data() {
        return {
            descripcion: null,
            files: [],
            dialog: false,
        };
    },
    created() {
        this.dialog = this.value;
        this.SetDescripcion();
    },
    watch: {
        value(val) {
            this.dialog = val;
            this.SetDescripcion();
        },
        dialog(val) {
            this.$emit("input", val);
            this.SetDescripcion();
        },
    },
    computed: {
        isMorning() {
            var currentTime = new Date();
            var currentHour = currentTime.getHours();
            // Define morning hours (for example, 6 AM to 11:59 AM)
            var morningStartHour = 6;
            var morningEndHour = 11;

            // Check if current hour is in the morning
            if (
                currentHour >= morningStartHour &&
                currentHour <= morningEndHour
            ) {
                return true;
            } else {
                return false;
            }
        },
    },
    methods: {
        SetDescripcion() {
            // console.log("prueba");
            this.descripcion = `<strong>${
                this.isMorning ? "Buenos días" : "Buenas tardes"
            }</strong>
<p>${
                this.kit
                    ? ` Mandamos adjunta factura correspondiente al Kit,para presentar su justificación. . Necesitamos nos envíes el
justificante de abono, recuerda que solo tienes que abonar la parte correspondiente al IVA, y anota en el concepto el número de factura.`
                    : ` Mandamos adjunta factura. Necesitamos nos envíes el justificante de abono, con el número de factura en el concepto.`
            }
</p>
<strong>Gracias por la confianza.<br />
Un saludo</strong>`;
        },
        async SendMail() {
            try {
                const formData = new FormData();
                formData.append('descripcion', this.descripcion);
               // Añade cada archivo al FormData
                this.files.forEach((file, index) => {
                    formData.append(`files[]`, file); // Usa `files[]` para indicar un array de archivos
                });

                const res = await axios.post(
                    `api/facturas/mail/${this.id_factura}`,
                    formData,
                    {
                        headers: {
                            'Content-Type': 'multipart/form-data',
                        },
                    }
                );
                this.dialog = false;
            } catch (ex) {
                console.log(ex);
            }
        },
    },
};
</script>
